import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { Container, Row, Column } from '../Grid';
import Heading from '../Heading/Heading';
import SectionHeader from '../SectionHeader/SectionHeader';
import { theme } from '../common/theme';
import { useRef } from 'react';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from '../common/utils';

const SectionContainer = styled(Box)`
  overflow-x: hidden;
`;

const StyledBox = styled(Box)`
  ${props =>
    props.hasBorderBottom &&
    css`
      border-bottom: 1px solid
        ${rgba(getColors[props.colorScheme].border, 0.25)};
    `}
  ${props =>
    props.hasBorderTop &&
    css`
      border-top: 1px solid ${rgba(getColors[props.colorScheme].border, 0.25)};
    `}
`;

export const getColors = {
  white: {
    title: 'baseBlack',
    bg: 'baseWhite',
    border: theme.colors.baseBlack,
  },
  black: {
    title: 'baseBlack',
    bg: 'baseGrayLight',
    border: theme.colors.baseBlack,
  },
  green: {
    title: 'brandGreen',
    bg: 'brandGreenPale',
    border: theme.colors.baseBlack,
  },
  purple: {
    title: 'brandPurple',
    bg: 'brandPurplePale',
    border: theme.colors.baseBlack,
  },
  violet: {
    title: 'brandViolet',
    bg: 'brandVioletPale',
    border: theme.colors.baseBlack,
  },
  red: {
    title: 'brandRed',
    bg: 'brandRedPale',
    border: theme.colors.baseBlack,
  },
  yellow: {
    title: 'brandYellow',
    bg: 'brandYellowPale',
    border: theme.colors.baseBlack,
  },
  navy: {
    title: 'brandNavy',
    bg: 'brandNavyPale',
    border: theme.colors.baseBlack,
  },
  gray: {
    title: 'baseBlack',
    bg: 'baseGrayVeryLight',
    border: theme.colors.baseBlack,
  },
  transparent: {
    title: 'baseBlack',
    bg: 'baseWhite',
    border: theme.colors.baseBlack,
  },
};

function useContainerMargin(container, slider) {
  useEffect(() => {
    if (container.current && slider.current) {
      slider.current.style.setProperty(
        'margin-left',
        `${container.current.offsetLeft}px`,
      );
      const handleResize = () => {
        slider.current.style.setProperty(
          'margin-left',
          `${container.current.offsetLeft}px`,
        );
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);
}
export const Section = props => {
  const {
    id,
    sectionTitle,
    intro,
    align,
    color,
    children,
    padding,
    pt,
    pb,
    bg,
    isGallery,
    renderCta,
    renderArrows,
    showTitle,
    hasBorderBottom,
    hasBorderTop,
    sectionHeaderWidth,
    sectionHeaderPx,
    sectionHeaderMb,
    customColors,
    ...rest
  } = props;

  const getPadding = {
    tiny: {
      pt: [4, 6],
      pb: [0, 0, 1],
    },
    small: {
      pt: [6, 8],
      pb: [8, 10],
    },
    medium: {
      pt: [8, 12],
      pb: [10, 14],
    },
    large: {
      pt: [10, 16],
      pb: [12, 18],
    },
  };

  let containerRef = useRef(null);
  let sliderRef = useRef(null);
  useContainerMargin(containerRef, sliderRef);

  const sectionColors = getColors[color]
    ? getColors[color]
    : getColors['white'];

  return (
    <SectionContainer
      id={id}
      pt={isGallery ? (pt ? pt : padding && getPadding[padding].pt) : null}
      pb={isGallery ? (pb ? pb : padding && getPadding[padding].pb) : null}
      bg={getColors[bg] ? getColors[bg].bg : sectionColors.bg}
      style={{ ...rest }}
    >
      <Container ref={containerRef}>
        <StyledBox
          className="Section_StyledBox"
          pt={!isGallery ? (pt ? pt : padding && getPadding[padding].pt) : null}
          pb={!isGallery ? (pb ? pb : padding && getPadding[padding].pb) : null}
          colorScheme={color}
          hasBorderBottom={hasBorderBottom}
          hasBorderTop={hasBorderTop}
        >
          <Row
            flexDirection={align === 'column' ? ['column', 'row'] : 'column'}
          >
            {showTitle || showTitle === null ? (
              align === 'column' ? (
                <Column width={[1, 3 / 12]} flex={'0 0 auto'}>
                  <Heading
                    size="h6"
                    textTransform="uppercase"
                    mb={[2, 0]}
                    color={
                      customColors ? customColors.title : sectionColors.title
                    }
                    mt={[2, 5]}
                  >
                    {sectionTitle}
                  </Heading>
                </Column>
              ) : (
                <Column width={[1]}>
                  <SectionHeader
                    sectionTitle={sectionTitle}
                    intro={intro}
                    color={
                      customColors ? customColors.title : sectionColors.title
                    }
                    align={align}
                    cta={renderCta(align, color)}
                    arrows={renderArrows}
                    width={sectionHeaderWidth}
                    px={sectionHeaderPx}
                    marginBottom={sectionHeaderMb}
                  />
                </Column>
              )
            ) : null}
            {!isGallery && align === 'column' ? (
              <Column width={[1, 9 / 12]} flex={'0 0 auto'}>
                {children}
              </Column>
            ) : null}
          </Row>
          {!isGallery && align !== 'column' ? <Box>{children}</Box> : null}
        </StyledBox>
      </Container>

      {isGallery ? <Box ref={sliderRef}>{children}</Box> : null}
    </SectionContainer>
  );
};

Section.propTypes = {
  /** Title of the Section passed to SectionHeader */
  sectionTitle: PropTypes.string,
  /** Intro of the Section passed to SectionHeader */
  intro: PropTypes.string,
  /** Children of component */
  children: PropTypes.node,
  /** Color of the Section passed to SectionHeader (Only "Brand" options)*/
  color: PropTypes.oneOf(Object.keys(getColors)).isRequired,
  /** Alignment of the text passed to SectionHeader. The Column align setting changes the layout of the heading and the children to be next to each other */
  align: PropTypes.oneOf(['left', 'right', 'center', 'column']),
  /** Padding value that set top and bottom padding and adjusts for mobile  */
  padding: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Padding top (rebass)value that will override the "padding" prop. (Only use as exception)  */
  pt: PropTypes.node,
  /** Padding bottom (rebass)value that will override the "padding" prop. (Only use as exception)  */
  pb: PropTypes.node,
  /** Set background color of Section (Only "Pale" options)*/
  bg: PropTypes.oneOf(Object.keys(theme.colors)),
  /** Function for Calls to action (passes "align" prop to function) */
  renderCta: PropTypes.func,
  /** Render Arrows for section slider */
  renderArrows: PropTypes.node,
  /** Should the section display the children as a slider */
  isGallery: PropTypes.bool,
  /** Should the title be displayed? */
  showTitle: PropTypes.bool,
  /** Should a border be above the section */
  hasBorderBottom: PropTypes.bool,
  /** Should a border be below the section */
  hasBorderTop: PropTypes.bool,
  /** ID to show in the DOM */
  id: PropTypes.string,
  customColors: PropTypes.object,
};

Section.defaultProps = {
  sectionTitle: null,
  intro: null,
  children: null,
  color: 'white',
  align: 'left',
  padding: 'medium',
  pt: null,
  pb: null,
  bg: null,
  renderCta: () => {},
  isGallery: false,
  renderArrows: null,
  showTitle: true,
  hasBorderBottom: false,
  hasBorderTop: false,
  id: null,
};

export default Section;
