import React from 'react';
import { graphql } from 'gatsby';
import Search from '../components/search';
import { FAQArchive } from '../components/faq/FAQArchive';
import { RESULT_TYPES } from '../components/search/hitComps';
import { ListPosts, Section } from '@sygnia/components';
import {
  CONTENT_TITLES,
  REFINEMENT_TYPES,
  VIEW_TYPES,
} from '../common/constants';

export const ARCHIVE_TYPES = {
  NEWS: 'news',
  FAQS: 'faqs',
  FUNDS: 'funds',
  CAREERS: 'careers',
  PERFORMANCE: 'fundPerformance',
  PORTFOLIO: 'portfolio',
  DOWNLOADS: 'documents',
  FORMS: 'forms',
  FAFORMS: 'fa_forms',
  SENS: 'sens',
  PRESENTATIONS: 'presentations',
  FINANCIAL_STATEMENTS: 'financial_statements',
  INTEGRATED_REPORTS: 'integrated_reports',
  REPORTS: 'reports',
  RELATED_NEWS: 'related_news',
  ANNOUNCEMENTS: 'announcements',
  RELEATED_CAREERS: 'related_careers',
  WEBINARS: 'webinars',
};

export const fundRefinements = [
  {
    type: REFINEMENT_TYPES.DROPDOWN,
    refinement: 'productType',
  },
  {
    type: REFINEMENT_TYPES.DROPDOWN,
    refinement: 'category',
  },
];

const viewFundsAsList = {
  title: VIEW_TYPES.LIST,
  hitComp: RESULT_TYPES.FUNDS_POST,
  isTable: true,
};

const viewFundsAsCard = {
  title: VIEW_TYPES.CARDS,
  hitComp: RESULT_TYPES.FUNDS_ARCHIVE,
  isCard: true,
  fullWidth: true,
  wrapperComponent: Section,
};

const viewFundsAsPerformance = {
  title: VIEW_TYPES.PERFORMANCE,
  hitComp: RESULT_TYPES.FUNDS_PERFORMANCE,
  isCard: false,
};

const ARCHIVE_MAPPING = {
  [ARCHIVE_TYPES.WEBINARS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'webinars_date_desc',
          title: 'Webinars',
          hitComp: RESULT_TYPES.WEBINAR_CARD,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'webinarCategory',
        },
      ],
      title: null,
      isSearchable: true,
      viewAs: [
        {
          title: VIEW_TYPES.CARDS,
          hitComp: RESULT_TYPES.WEBINAR_CARD,
          isCard: true,
          fullWidth: true,
          wrapperComponent: Section,
        },
        {
          title: VIEW_TYPES.LIST,
          hitComp: RESULT_TYPES.WEBINAR_LIST,
          isTable: true,
        },
      ],
    },
  },
  [ARCHIVE_TYPES.CAREERS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'careers',
          title: 'Careers',
          hitComp: RESULT_TYPES.CAREERS_POST,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'location',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'department',
        },
      ],
      title: null,
      isSearchable: true,
      isWrapped: true,
    },
  },
  [ARCHIVE_TYPES.NEWS]: {
    component: Search,
    options: {
      indices: [{ name: 'news_date_desc', title: 'News', hitComp: 'NewsHit' }],
      refinements: [
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'category',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'year',
        },
      ],
      title: null,
      isSearchable: true,
      isWrapped: false,
      perPageOptions: [
        {
          value: 20,
          label: '20',
        },
        {
          value: 50,
          label: '50',
        },
        {
          value: 100,
          label: '100',
        },
      ],
      perPageLimit: 20,
    },
  },
  [ARCHIVE_TYPES.FAQS]: {
    component: FAQArchive,
    options: {
      isSearchable: true,
    },
  },
  [ARCHIVE_TYPES.FUNDS]: {
    component: Search,
    options: {
      indices: [
        { name: 'funds', title: 'Funds', hitComp: RESULT_TYPES.FUNDS_POST },
      ],
      viewAs: [viewFundsAsList, viewFundsAsCard, viewFundsAsPerformance],
      refinements: fundRefinements,
      limit: 15,
      isSearchable: false,
      isWrapped: false,
      perPageLimit: 50,
    },
  },
  [ARCHIVE_TYPES.PORTFOLIO]: {
    component: Search,
    options: {
      indices: [
        { name: 'funds', title: 'Funds', hitComp: RESULT_TYPES.FUNDS_POST },
      ],
      viewAs: [viewFundsAsList, viewFundsAsPerformance, viewFundsAsCard],
      refinements: fundRefinements,
      limit: 15,
      isSearchable: false,
      isWrapped: false,
      perPageLimit: 50,
    },
  },
  [ARCHIVE_TYPES.PERFORMANCE]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'funds',
          title: 'Funds',
          hitComp: RESULT_TYPES.FUNDS_PERFORMANCE,
        },
      ],
      viewAs: [viewFundsAsPerformance, viewFundsAsCard, viewFundsAsList],
      refinements: fundRefinements,
      limit: 15,
      isSearchable: false,
      isWrapped: false,
      perPageLimit: 50,
    },
  },
  [ARCHIVE_TYPES.DOWNLOADS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents_date_desc',
          title: 'Downloads',
          hitComp: RESULT_TYPES.DOWLOAD_POST,
        },
      ],
      viewAs: [
        {
          title: 'Downloads',
          hitComp: RESULT_TYPES.DOWLOAD_POST,
          isTable: true,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'category',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'year',
        },
      ],
      isSearchable: true,
      isWrapped: true,
      hasFilter: true,
    },
  },
  [ARCHIVE_TYPES.FORMS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents_title_asc',
        },
      ],
      viewAs: [
        {
          hitComp: RESULT_TYPES.DOWLOAD_POST,
          isTable: true,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.BUTTONGROUP,
          refinement: 'tags',
        },
      ],
      isSearchable: true,
      isWrapped: true,
      hasFilter: true,
    },
  },
  [ARCHIVE_TYPES.FAFORMS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents_title_asc',
        },
      ],
      viewAs: [
        {
          hitComp: RESULT_TYPES.DOWLOAD_POST,
          isTable: true,
        },
      ],
      refinements: [],
      isSearchable: false,
      isWrapped: true,
      hasFilter: false,
    },
  },
  [ARCHIVE_TYPES.SENS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents_date_desc',
        },
      ],
      viewAs: [
        {
          hitComp: RESULT_TYPES.DOWLOAD_POST,
          isTable: true,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.BUTTONGROUP,
          refinement: 'tags',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'year',
        },
      ],
      isSearchable: true,
      isWrapped: true,
      hasFilter: true,
    },
  },
  [ARCHIVE_TYPES.PRESENTATIONS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents_date_desc',
        },
      ],
      viewAs: [
        {
          hitComp: RESULT_TYPES.DOWLOAD_POST,
          isTable: true,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.BUTTONGROUP,
          refinement: 'tags',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'year',
        },
      ],
      isSearchable: true,
      isWrapped: true,
      hasFilter: true,
    },
  },
  [ARCHIVE_TYPES.INTEGRATED_REPORTS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents_date_desc',
        },
      ],
      viewAs: [
        {
          hitComp: RESULT_TYPES.DOWLOAD_POST,
          isTable: true,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.BUTTONGROUP,
          refinement: 'tags',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'year',
        },
      ],
      isSearchable: true,
      isWrapped: true,
      hasFilter: true,
    },
  },
  [ARCHIVE_TYPES.FINANCIAL_STATEMENTS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents_date_desc',
        },
      ],
      viewAs: [
        {
          hitComp: RESULT_TYPES.DOWLOAD_POST,
          isTable: true,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.BUTTONGROUP,
          refinement: 'tags',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'year',
        },
      ],
      isSearchable: true,
      isWrapped: true,
      hasFilter: true,
    },
  },
  [ARCHIVE_TYPES.REPORTS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents_date_desc',
          title: 'Reports',
          hitComp: RESULT_TYPES.REPORT_POST,
        },
      ],
      viewAs: [
        {
          title: 'Reports',
          hitComp: RESULT_TYPES.REPORT_POST,
          isTable: true,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'category',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'year',
        },
      ],
      isSearchable: true,
      isWrapped: true,
      hasFilter: true,
    },
  },
  [ARCHIVE_TYPES.FINANCIALS]: {
    component: Search,
    options: {
      indices: [
        {
          name: 'documents',
          title: 'Downloads',
          hitComp: RESULT_TYPES.DOWLOAD_POST,
        },
      ],
      refinements: [
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'category',
        },
        {
          type: REFINEMENT_TYPES.DROPDOWN,
          refinement: 'year',
        },
      ],
      title: 'Unit Trusts / ETFs',
      isSearchable: true,
      isTable: true,
      hasFilter: true,
      perPage: 10,
      isWrapped: true,
    },
  },
  [ARCHIVE_TYPES.RELATED_NEWS]: {
    component: Search,
    wrapper: {
      component: ListPosts,
      props: {
        title: CONTENT_TITLES.LATEST_NEWS,
      },
    },
    options: {
      hasFilter: false,
      isWrapped: false,
      perPageLimit: 5,
      indices: [
        {
          name: `news_date_desc`,
          title: `news`,
          hitComp: RESULT_TYPES.NEWS_POST,
          fullWidth: true,
        },
      ],
    },
  },
  [ARCHIVE_TYPES.ANNOUNCEMENTS]: {
    component: Search,
    wrapper: {
      component: ListPosts,
      props: {
        title: CONTENT_TITLES.LATEST_ANNOUNCEMENTS,
      },
    },
    options: {
      hasFilter: false,
      isWrapped: false,
      perPageLimit: 5,
      showPagination: true,
      indices: [
        {
          name: `documents_date_desc`,
          title: `documents`,
          hitComp: RESULT_TYPES.ANNOUNCEMENT,
          fullWidth: true,
        },
      ],
    },
  },
};

export const ArchiveWrapper = ({ section }) => {
  const { component: Component, options, wrapper } =
    ARCHIVE_MAPPING[section.type] || {};
  if (!Component) return null;

  if (wrapper) {
    const {
      component: WrapperComponent = React.Fragment,
      props: wrapperProps = {},
    } = wrapper;

    return (
      <WrapperComponent {...wrapperProps}>
        <Component
          {...options}
          {...section}
          virtual={options.virtual ? options.virtual : section.virtual}
        />
      </WrapperComponent>
    );
  }

  return (
    <Component
      {...options}
      {...section}
      virtual={options.virtual ? options.virtual : section.virtual}
    />
  );
};

ArchiveWrapper.defaultProps = {
  section: {},
};

export const fragment = graphql`
  fragment ArchiveOptions on contentfulLayoutComponentComponentJsonNodeProps {
    type
    virtual {
      key
      value
    }
    isSearchable
    isWrapped
    showLink
  }
`;
