import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Section } from '@sygnia/components';
import { getEmText, mapper } from '../common/utils';
import { ProductCardWrapper } from './ProductCardWrapper';
import { FundCardWrapper } from './FundCardWrapper';
import { Row, Column, Slider, ButtonArrow } from '@sygnia/components';
import { Flex } from 'rebass';
import {
  InfoCardWrapper,
  CardPrimaryWrapper,
  CardWrapper,
} from './CardWrapper';
import Layout from './layout';
import { CONTENT_TYPES } from '../common/constants';
import { PeopleCardWrapper } from './PeopleCardWrapper';
import LinkGroup from './LinkGroup';
import { MenuArchive } from '../components/menu/MenuArchive';
import { sizes } from '../common/utils';
import { ShowAt } from '@sygnia/components';
import { TestimonialWrapper } from './TestimonialWrapper';
const SECTION_CONTENT_MAP = {
  intro: ['description', 'childMarkdownRemark', 'html'],
  title: ['title'],
  style: ['style'],
};

const CARD_MAPPING = {
  [CONTENT_TYPES.INFO_CARD]: InfoCardWrapper,
  [CONTENT_TYPES.PRODUCT_CARD]: ProductCardWrapper,
  [CONTENT_TYPES.FUND_CARD]: FundCardWrapper,
  [CONTENT_TYPES.CONTENT_PAGE]: CardPrimaryWrapper,
  [CONTENT_TYPES.PRIMARY_CARD]: CardPrimaryWrapper,
  [CONTENT_TYPES.CONTENT_CARD]: CardWrapper,
  [CONTENT_TYPES.CHILDREN]: Layout,
  [CONTENT_TYPES.COMPONENT]: Layout,
  [CONTENT_TYPES.CONTENT_PEOPLE]: PeopleCardWrapper,
  [CONTENT_TYPES.CONTENT_MENU]: MenuArchive,
  [CONTENT_TYPES.TESTIMONIAL]: TestimonialWrapper,
};

export const SectionWrapper = ({ section, colorScheme }) => {
  if (!section.modules) return null;
  const { title: _title, intro, style } = mapper(section, SECTION_CONTENT_MAP);
  const color =
    section.color && section.color !== CONTENT_TYPES.INHERIT
      ? section.color.toLowerCase() // TODO: add color function
      : colorScheme;

  const bg =
    section.backgroundColor && section.backgroundColor !== CONTENT_TYPES.INHERIT
      ? section.backgroundColor.toLowerCase() // TODO: add color function
      : color;

  const arrowsComponent = (
    <Flex>
      <ButtonArrow
        mr={1}
        color="black"
        direction="left"
        onClick={() => sliderRef.current.slickPrev()}
      />
      <ButtonArrow
        color="black"
        direction="right"
        onClick={() => sliderRef.current.slickNext()}
      />
    </Flex>
  );

  const {
    component: ComponentWrapper,
    options,
    sectionOptions,
  } = section.isGallery
    ? {
        component: Slider,
        options: {
          options: {
            slidesToShow: 3.5,
            infinite: false,
            arrows: false,
            responsive: [
              {
                breakpoint: sizes.desktop,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: sizes.phone,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          },
          CustomArrowComponent: null,
        },

        sectionOptions: section => {
          if (section.modules && section.modules.length > 1) {
            return {
              renderArrows: (
                <>
                  <ShowAt breakpoint="small">
                    {section.modules.length > 2 ? arrowsComponent : null}
                  </ShowAt>
                  <ShowAt breakpoint="mediumAndAbove">
                    {section.modules.length > 3 ? arrowsComponent : null}
                  </ShowAt>
                </>
              ),
            };
          }

          return {};
        },
      }
    : {
        component: Row,
        options: {
          flexDirection: ['column', 'column', 'row'],
          alignItems: ['center', 'stretch'],
          flexWrap: 'wrap',
        },
      };

  let sliderRef;
  const setRef = useCallback(ref => (sliderRef = ref));

  const renderCta = section.links
    ? () => <LinkGroup color={color} links={section.links || []} />
    : () => null;

  let title = <span>{_title}</span>;
  if (
    section.style?.titleAnnotations &&
    section.style?.titleAnnotations.length
  ) {
    const [annotation] = section.style.titleAnnotations;
    title = getEmText(_title, annotation.value);
  }

  return (
    <Section
      id={section.id}
      showTitle={section.showTitle}
      sectionTitle={title}
      intro={intro}
      color={color}
      align={section.alignment ? section.alignment.toLowerCase() : 'left'}
      bg={bg}
      isGallery={section.isGallery}
      hasBorderBottom={section.alignment === 'Column' ? true : false}
      renderCta={renderCta}
      {...style}
      {...(sectionOptions ? sectionOptions(section) : {})}
    >
      <ComponentWrapper
        setRef={setRef}
        {...options}
        className="ComponentWrapper"
      >
        {section.modules &&
          section.modules.map((mod, i) => {
            if (mod === null) return null;
            let key = section.layout;

            if (
              [
                CONTENT_TYPES.COMPONENT,
                CONTENT_TYPES.CONTENT_PEOPLE,
                CONTENT_TYPES.CONTENT_MENU,
                CONTENT_TYPES.TESTIMONIAL,
              ].includes(mod.__typename)
            ) {
              key = mod.__typename;
            }

            const Component = CARD_MAPPING[key];

            if (!Component) return null;

            //flex = temporary / coincidental fix.
            //https://app.asana.com/0/1125056784663001/1139202581530961/f

            return (
              <Column
                key={i}
                className="SectionWrapper_Column"
                flex={
                  section.modules.length > 3
                    ? ['0 0 auto']
                    : ['1 0 auto', '1 0 auto', '1 1 auto']
                }
                width={
                  section.isFeatured
                    ? [1]
                    : section.modules.length === 4
                    ? [1, 1, 2 / 4]
                    : [1, 1, 1 / 3]
                }
                mb={[1, 1, 4]}
              >
                <Component
                  isFeatured={section.isFeatured}
                  color={color}
                  key={i}
                  section={mod}
                  layout={[mod]}
                  inColumn={section.alignment === 'Column' ? true : false}
                />
              </Column>
            );
          })}
      </ComponentWrapper>
    </Section>
  );
};

SectionWrapper.defaultProps = {
  section: {},
};

export const fragment = graphql`
  fragment SectionLayoutFields on ContentfulLayoutSectionWithChildren {
    id
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    layout
    color
    backgroundColor
    alignment: aligment
    isFeatured: showFeatureCards
    isGallery
    showTitle
    links {
      ...LinkFields
    }
    style {
      customColors {
        title
      }
      sectionHeaderMb
      padding
      sectionHeaderPx
      sectionHeaderWidth
      borderTop
      borderBottom
      pb
      titleAnnotations {
        type
        color
        value
      }
    }
    modules {
      __typename
      ... on Node {
        ...ProductCardLayoutFields
        ...FundCardLayoutFields
        ...ContentGenericFields
        ...ToggleLayoutFields
        ...SliderLayoutFields
        ...ComponentLayoutFields
        ...LayoutColumnFields
        ...LayoutMenuFields
        ...TestimonialsLayoutFields
        ...QNALayoutFields
        ... on ContentfulPage {
          slug
          label: name
          pageSummary {
            title
            content {
              childMarkdownRemark {
                html
              }
            }
            colorScheme
            meta {
              label
            }
            gtmEventInfo: meta {
              internal {
                content
              }
            }
          }
          contentfulparent
        }
        ...PeopleLayoutFields
      }
    }
  }
`;

SectionWrapper.fragment = fragment;
