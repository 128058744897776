import { STATIC_LINKS } from '../../common/constants';

export const form_index = form => {
  const forms = {
    formIntro: formIntro,
    sections: sections,
  };

  return forms[form];
};
export const formIntro = {
  title: ' Two-pot tax calculator',
  label: 'Two-pot tax calculator',
  description: `Before you make a savings withdrawal, use our tax calculator to see how much you can expect to receive after tax.`,
  fields: [
    {
      type: 'section',
      fields: [
        {
          name: 'terms',
          type: 'checkbox',
          required: true,
          label: STATIC_LINKS.termsLinkV2,
        },
      ],
    },
  ],
};
const sections = {
  age: {
    type: 'section',
    label: 'How old are you?',
    description:
      'We use your age to calculate the applicable tax rebate. Don’t worry – we will never share your personal information.',
    fields: [
      {
        name: 'age',
        type: 'number',
        label: 'Your age',
        defaultValue: 30,
      },
    ],
  },
  income: {
    type: 'section',
    label: 'What is your gross annual income?',
    description:
      'Your gross annual income (your yearly salary and any other sources of income, like interest on investments and rent on property) determines your marginal tax rate. This is your total income before tax deductions.',
    fields: [
      {
        name: 'income',
        type: 'text',
        prefixText: 'R',
        label: 'Amount',
        required: true,
        inputmode: 'numeric',
        pattern: '[0-9]*',
      },
    ],
  },
  withdrawal: {
    type: 'section',
    label: 'What is your savings withdrawal amount?',
    description:
      'Check your savings pot balance: <ol> <li> Log in at <a href=`https://epic.sygnia.com/login`/ target=`_blank`> https://epic.sygnia.com/login </a> or </li><li> Dial * 120 * 794642# and complete the necessary authentication. </li></ol> Note that withdrawals are only allowed on balances of R2 000 or more.',
    fields: [
      {
        name: 'withdrawalAmount',
        type: 'text',
        prefixText: 'R',
        label: 'Amount',
        required: true,
        inputmode: 'numeric',
        pattern: '[0-9]*',
      },
    ],
  },
};

export const formTwoPot = {
  title: 'Two Pot Retirement Calculator',
  content: 'Retirement Annuity.',
  initConfig: {
    age: 30,
  },
  fields: [sections.age, sections.income, sections.withdrawal],
};

export const getFieldConditional = (field, currentValues = {}) => {
  if (field.conditional) {
    const math_it_up = {
      is: (x, y) => {
        return x === y;
      },
      isnot: (x, y) => {
        return x !== y;
      },
      includes: (x, y) => {
        return x.includes(y);
      },
      interect: (x, y) => {
        var z = x.filter(function(val) {
          return y.split(',').indexOf(val) !== -1;
        });
        return z.length > 0;
      },
    };

    const {
      conditionalLogic: { actionType, logic },
    } = field;

    let a;

    if (typeof currentValues[logic.field] === 'undefined') {
      a = false;
    } else {
      a = math_it_up[logic.match](currentValues[logic.field], logic.value);
    }

    if (a) {
      if (actionType === 'show') {
        return true;
      } else {
        return false;
      }
    } else {
      if (actionType === 'show') {
        return false;
      } else {
        return true;
      }
    }
  }

  return true;
};
