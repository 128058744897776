import React from 'react';
import { Card } from '@sygnia/components';
import { mapper } from '../common/utils';
import LinkGroup, { BUTTON_TYPES } from './LinkGroup';
import { graphql } from 'gatsby';
import {
  CONTENT_TITLES,
  GTM_CATEGORY,
  GTM_EVENT,
  GTM_LOCATION,
} from '../common/constants';

const FUND_CONTENT_MAP = {
  text: ['fundSummary', 'content', 'childMarkdownRemark', 'excerpt'],
  cardTitle: ['fundSummary', 'title'],
  image: ['fundSummary', 'image', 'fluid', 'src'],
};

export const FundCardWrapper = props => {
  const { section } = props;
  let fundLinks = [];
  if (section.slug)
    fundLinks.push({
      label: CONTENT_TITLES.moreInfo,
      to: `/fund/${section.slug}/`,
    });

  if (section.marketingSheet) {
    fundLinks.push({
      label: CONTENT_TITLES.marketingSheet,
      type: BUTTON_TYPES.DOWNLOAD,
      to: section.marketingSheet.file.url,
      external: true,
      gtmEventInfo: {
        eventName: GTM_EVENT.MARKETING_SHEET_DOWNLOADED,
        data: {
          category: GTM_CATEGORY.DOCUMENT,
          label: CONTENT_TITLES.marketingSheet,
          location: GTM_LOCATION.CONTENT,
        },
      },
    });
  }

  const renderCta =
    fundLinks.length > 0 ? (
      <LinkGroup color={'black'} links={fundLinks} />
    ) : null;

  return (
    <Card
      {...section}
      {...mapper(section, FUND_CONTENT_MAP)}
      mb={[2]}
      renderCta={() => renderCta}
    />
  );
};

FundCardWrapper.defaultProps = {
  section: {},
  contentType: 'fund',
};

export const FundCardLayoutFields = graphql`
  fragment FundCardLayoutFields on ContentfulFunds {
    fundInfoID: contentful_id
    title
    slug
    marketingSheet {
      file {
        url
      }
    }
    factSheet {
      file {
        url
      }
    }
    fundSummary {
      title
      content {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 100, truncate: true)
        }
      }

      image {
        fluid {
          src
        }
      }
    }
  }
`;

FundCardWrapper.fragment = FundCardLayoutFields;
